import React from 'react'
import './Preloader.css'

export default function Preloader() {
  return (
    <div className='preloader-wrapper'>
        <h1 className='preloader-title title-with-line'>Ntua<mark>flix</mark></h1>
        <div class="boxes">
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
  )
}
