import axios from "axios";




const axiosInstance = axios.create({
    // this is the base url
    baseURL: 'https://ntuaflix-api.ddnsfree.com/ntuaflix_api/',
    headers: {
        'Content-Type': 'application/json',        
    }
})


export default axiosInstance;
